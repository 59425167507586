import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from "@mui/material/Typography";
import { appTheme } from '../../theme/colorTheme';
import Rating from '@mui/material/Rating';
import { localStorageKey } from '../../constants/LocalStorageKey';
import { useDispatch } from 'react-redux';
import { setIsAppDownloadSnackbarOpen } from '../../utils/appSlice';
import constant from '../../constants/Constatnt';
import androidLogo from '../../img/menu-apk-option.svg';
import useMediaQuery from '@mui/material/useMediaQuery';

const SnackBarAppDownloadScreen = ({ setOpenAppDownloadSnackbar }) => {
    const extraSmallDevice = useMediaQuery('(max-width:600px)');
    const [open, setOpen] = React.useState(true);
    const dispatch = useDispatch();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        localStorage.setItem(localStorageKey.ISDOWNLOADAPPSNACKBAROPEN, JSON.stringify(false));
        setOpenAppDownloadSnackbar(false);
        setOpen(false);
        dispatch(setIsAppDownloadSnackbarOpen())
    };

    const action = (
        <React.Fragment>
            <Button size="small" onClick={() => {
                handleClose();
                window.open(constant.apkUrl);
            }} sx={{
                fontSize: 11,
                backgroundColor:appTheme.color12, color: appTheme.color1,
                '&:hover': { backgroundColor: appTheme.color12 },
                textTransform: 'none', marginRight: '10px',
                fontWeight: 'bold',
            }}>
                Install
            </Button>
        </React.Fragment>
    );

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                onClose={handleClose}
                sx={{mb: extraSmallDevice ? 1 : 3, position: 'relative', '& .MuiSnackbarContent-root': { width: '95%', borderRadius: '0', backgroundColor: '#111827' } }}
                message={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>
                            <IconButton

                                aria-label="close"
                                color="inherit"
                                onClick={handleClose}
                                sx={{
                                    marginLeft: '-10px', width: '20px', height: '20px', marginRight: '10px',
                                    backgroundColor: appTheme.color6,
                                    color: appTheme.color0,
                                    borderRadius: '50%',
                                    '&:hover': { backgroundColor: appTheme.color6, color: appTheme.color0 },
                                }}
                            >
                                <CloseIcon sx={{ fontSize: '15px' }} />
                            </IconButton>
                        </div>
                        <div>
                            <img src={androidLogo} alt="androidLogo" style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                        </div>
                        <div>
                            <Typography variant="body1" component="div" fontSize={11} fontWeight={'bold'} color={appTheme.color1}>Android Application</Typography>
                            <Typography variant="caption" component="div" fontSize={9} color={appTheme.color6}>Instant Download on your device </Typography>
                            <div style={{margin:0, padding:0}}>
                                <Rating
                                    name="simple-controlled"
                                    value={5}
                                    sx={{ fontSize: 10, margin:0, padding:0 }}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                }
                action={action}
            />
        </>
    );
}

const SnackBarAppDownload = React.memo(SnackBarAppDownloadScreen);

export default SnackBarAppDownload;

const constant = {
    websiteName:"DreamBaazi",
    baseUrl:"https://drumbzi.in/",
    whatsAppNumber:"+918000000791",
    instagramUrl:"https://instagram.com/dream_bazzi?igshid=NDk5N2NlZjQ=",
    telegramUrl:"https://t.me/dreambazzi",
    youTubeUrl:"https://api.whatsapp.com/send/?phone=8000000791&text&type=phone_number&app_absent=0",
    apkUrl:"https://baazi-cdn.sgp1.cdn.digitaloceanspaces.com/dreambazzi/DreamBaaziV2.apk",
    landingPageLogoSize:"100px",
    logoPath:"logo.jpeg",
    minDepositCoins:100,
    minWithdrawalCoins:500,
    howToPayBankVid: "https://baazi-cdn.sgp1.cdn.digitaloceanspaces.com/howtoplay/UpiToBankAccountFairBaazi.mp4", 
    numberOfIdsOnHome: 15,
    maxHoursToShowPaymentVideo: 24,

    transactionType: {
        depositWallet: 'DepositWalletRequest',
        withdrawWallet: 'WithdrawWalletRequest',
        depositId: 'DepositIdRequest',
        withdrawId: 'WithdrawIdRequest',
        createIdRequest: 'CreateIdRequest',
        changeIdPasswordRequest: 'ChangeIdPasswordRequest',
        closeIdRequest: 'CloseIdRequest',
    },
    transactionFor: {
        idDeposit: 'idDeposit',
        walletDeposit: 'walletDeposit',
        createIdDeposit: 'createIdDeposit',
        changePasswordRequest: 'changePasswordRequest'
    },
    accountType: {
        savings: 'savings',
        current: 'current'
    },
    transactionStatus: {
        success: 'success',
        scanning: 'scanning',
        failure: 'failure',
        pending: 'pending',
        created: 'created',
        error:'error',
        cancelled:'cancelled',
        approved:'approved',
        rejected:'rejected',
    },
    appHostsAndIds:[
    {
        host:'www.fairbaazi.com',
        appId:'87761855-eae4-4281-801d-a00d2b04dc52'
    },
    {
        host:'fairbaazi.com',
        appId:'be7aeb14-415c-4bcb-a228-e6613adfa6e8'
    },
    {
        host:'www.fairbaazi.co',
        appId:'f61b95e7-7475-4aca-840d-93dcd960604e'
    },
    {
        host:'fairbaazi.co',
        appId:'e5449d6f-a794-42b0-ad6a-de62eb5f36e6'
    },
    {
        host:'www.fairbaazi.in',
        appId:'2157079e-b5e0-4f07-909c-1c64980c6ec6'
    },
    {
        host:'fairbaazi.in',
        appId:'0fc2437a-46df-4b9a-9f2d-65e3d0514d2c'
    },
    {
        host:'www.fairbaazi.co.in',
        appId:'1ec733cc-8229-4f8b-8277-81ccc5b27ba4'
    },
    {
        host:'fairbaazi.co.in',
        appId:'dc917f85-13f1-4b72-b2d7-bdb9b34d7e36'
    },
    {
        host:'www.lotusbaazi.com',
        appId:'63b68f65-575d-4200-a6f1-3976ae3b1a39'
    },
    {
        host:'www.lotusbaazi.co.in',
        appId:'2f86edd6-b549-487d-b684-16060952e759'
    },
    {
        host:'www.lotusbaazi.in',
        appId:'b0b21db6-21d8-46fd-8ef3-0ea909e702f6'
    },
    {
        host:'lotusbaazi.co.in',
        appId:'f0359811-9b8a-4d07-84f8-e874b41dc7d5'
    },
    {
        host:'lotusbaazi.in',
        appId:'38d4f68a-5a09-491a-bb10-0a1c0b1606c8'
    },
    {
        host:'lotusbaazi.com',
        appId:'63b68f65-575d-4200-a6f1-3976ae3b1a39'
    },
    {
        host:'worldbaazi.co.in',
        appId:'daf510cf-1be9-409e-bc14-550f42bf69e0'
    },
    {
        host:'www.worldbaazi.co.in',
        appId:'fead8b49-1e36-422e-b6ad-cd394b6ce20b'
    },
    {
        host:'www.worldbaazi.in',
        appId:'367048bb-bf5e-43ae-b53b-65a09a7bd7e1'
    },
    {
        host:'worldbaazi.in',
        appId:'fd83dc31-7f01-4c3e-af1a-c7cd1df86142'
    },
    {
        host:'www.worldbaazi.com',
        appId:'e854fa63-640e-42c3-b405-c51d7bb00b56'
    },
    {
        host:'dreambaazi.com',
        appId:'84457af4-a978-4e3f-92a0-3dc3fff6c82a'
    },
    {
        host:'www.dreambaazi.com',
        appId:'215aef3e-5598-4a1d-ba74-91a3d93a20a9'
    },
    ]
}

export default constant;